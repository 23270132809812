<template>
    <div class="group">
        <div class="tabs">
            <ul>
                <li @click="_tabsActive(0)" :class="tabsActive==0?'active':''">基础报表</li>
                <li @click="_tabsActive(1)" :class="tabsActive==1?'active':''">中心报表</li>
                <!-- <li @click="_tabsActive(2)" :class="tabsActive==2?'active':''">个人报表</li> -->
                <!-- <li @click="_tabsActive(3)" :class="tabsActive==3?'active':''">新报表</li> -->
            </ul>
        </div>
        <div v-if="tabsActive==0">
            <CloudcoachReport></CloudcoachReport>
        </div>
        <div v-if="tabsActive==1">
            <CloudcoachReportBySchool></CloudcoachReportBySchool>
        </div>
        <div v-if="tabsActive==2">
            <CloudcoachReportStudent></CloudcoachReportStudent>
        </div>
        <!-- <div v-if="tabsActive==3">
            <CloudcoachReportNew></CloudcoachReportNew>
        </div> -->
    </div>
</div>
</template>

<script>
    import moment from 'moment';
    import CloudcoachReport from 'pages/Cloudcoach/CloudcoachReport';
    import CloudcoachReportBySchool from 'pages/Cloudcoach/CloudcoachReportBySchool';
    import CloudcoachReportStudent from 'pages/Cloudcoach/CloudcoachReportStudent';
    import CloudcoachReportNew from 'pages/Cloudcoach/CloudcoachReportNew';
    export default {
        name: "CloudcoachReportBase",
        components: {CloudcoachReport,CloudcoachReportBySchool,CloudcoachReportStudent,CloudcoachReportNew},
        data(){
            return{
                tabsActive:0,
                treeData:[],
            }
        },
        methods:{
            moment
        },
        created(){
            this._getOrgList();
        },
        mounted(){
            
        },
        computed: {


        },
        methods:{
            _tabsActive(i){
                this.tabsActive = i;
            },
            _getOrgList(){
                this.$axios.get(1072,{
                    Pid:'',
                },res=>{
                    if (res.data.code == 1) {
                        this.treeData = res.data.list;
                    }
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    *{
        font-family:Microsoft YaHei;
    }
    .tabs{
        border-bottom: 1px solid #CCD1DC;
        border-left: 1px solid #CCD1DC;
        border-top-left-radius: 5px;

        ul{
            li{
                display: inline-block;
                width: 85px;
                padding: 6px 0;
                text-align: center;
                border: 1px solid #CCD1DC;
                border-bottom: none;
                cursor: pointer;
                border-left: 0;
                &:first-child{
                    border-top-left-radius: 5px;
                }
                &:last-child{
                    border-top-left-radius: 0;
                    border-top-right-radius: 5px;
                }
            }
            .active{
                background: #1890FF;
                color: #fff;
                border: 1px solid #1890FF;
            }
        }
    }
    .content{
        margin-top: 15px;
        display: flex;
        min-height: 800px;
        .tree{
            width: 200px;
            background: #ffffff;
            padding: 15px;
            border: 1px solid #DFE0E6;
            border-radius: 5px;
            .company{
                color: #242424;
                font-size:14px;
            }
        }
        .list{
            padding: 15px;
            background: #ffffff;
            margin-left: 20px;
            flex: 1;
            padding-right: 75px;
            .list-title{
                /*width: 400px;*/
                li{
                    width: 15.8%;
                    margin-right: 1%;
                    float: left;
                    border:1px solid rgba(224,234,244,1);
                    text-align: center;
                    border-radius: 5px;
                    padding: 10px 0px;
                    cursor: pointer;
                    span{
                        display: block;
                        font-size:14px;
                        color:rgba(153,153,153,1);
                    }
                    .typeNum{
                        color:rgba(102,102,102,1);
                        font-size: 16px;
                    }
                }
                .title-active{
                    border:1px solid rgba(41,148,255,1);
                }
                li:last-child{
                    margin-right: 0;
                }
            }

        }
    }
    .search{
        display: flex;
        margin-top: 20px;
        margin-bottom: 25px;
        .search-style{
            margin-right: 10px;
        }
    }
    .hook{
        color:rgba(41,148,255,1);
        cursor: pointer;
    }
</style>